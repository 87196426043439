var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-1/2 flex flex-col justify-center p-2",staticStyle:{"top":"0","left":"0","bottom":"0","right":"0","margin":"5% auto"}},[_vm._m(0),_c('div',{staticClass:"flex flex-col w-full my-6"},[_c('div',{staticClass:"border rounded-md w-full flex-col p-5"},[_c('div',[_c('radio-button',{attrs:{"space-between":"ml-2 mr-3 my-3 text-darkPurple text-xs flex flex-col","col-span":"col-span-12 hover-radioBtn","hover":"","options":[
            {
              name: 'Use my data, including current email & password as the account owner.',
              radioName: 'mode',
              value: true,
            },
          ]},model:{value:(_vm.selectMode),callback:function ($$v) {_vm.selectMode=$$v},expression:"selectMode"}})],1)])]),_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"border w-full flex flex-col p-6 bg-ghostWhite rounded-sm"},[_c('div',{staticClass:"w-full flex flex-col mb-3"},[_c('span',{staticClass:"text-sm font-semibold"},[_vm._v("Full Name:")]),_c('span',{staticClass:"text-base"},[_vm._v(_vm._s(_vm.$AuthUser.fname)+" "+_vm._s(_vm.$AuthUser.lname))])]),_c('div',{staticClass:"w-full flex flex-col my-3"},[_c('span',{staticClass:"text-sm font-semibold"},[_vm._v("Contact Email:")]),_c('span',{staticClass:"text-base"},[_vm._v(_vm._s(_vm.$AuthUser.email))])]),_c('div',{staticClass:"w-full flex flex-col my-3"},[_c('span',{staticClass:"text-sm font-semibold"},[_vm._v("Contact Phone No:")]),_c('span',{staticClass:"text-base"},[_vm._v(_vm._s(_vm.$AuthUser.phoneNo))])])])]),_c('div',{staticClass:"pl-3 flex w-full justify-end mt-5"},[_c('div',{staticClass:"flex"},[_c('Button',{class:_vm.disableBtn ? `bg-disabled` : `bg-dynamicBackBtn text-white`,attrs:{"disabled":_vm.disableBtn},on:{"click":function($event){return _vm.moveNext()}}},[_c('span',{staticClass:"mr-6 ml-6"},[_vm._v("Next ")])]),_c('Button',{staticClass:"ml-4",staticStyle:{"color":"#321c3b"},attrs:{"background-color":"none"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-xl font-black my-3"},[_vm._v(" Confirm Your Data ")]),_c('div',{staticClass:"w-full flex flex-grow"},[_c('span',{staticClass:"flex flex-grow text-base text-romanSilver"},[_vm._v("Confirm your data to proceed.")]),_c('span',{staticClass:"flex"},[_c('span',{staticClass:"flex flex-grow text-base text-flame font-black"},[_vm._v("2")]),_c('span',{staticClass:"flex text-base text-romanSilver mx-1"},[_vm._v("of")]),_c('span',{staticClass:"flex flex-grow text-base text-romanSilver font-black"},[_vm._v("3")])])])])
}]

export { render, staticRenderFns }