<template>
  <div class="w-full max-h-screen">
    <first-step @nextLevel="moveNextLevel($event)" v-if="level === 1" />
    <second-step @nextLevel="moveNextLevel($event)" v-if="level === 2" />
    <third-step @nextLevel="moveNextLevel($event)" v-if="level === 3" />
  </div>
</template>

<script>
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

export default {
  name: "AccountSetup",
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
  },
  data() {
    return {
      level: 1,
    };
  },
  methods: {
    moveNextLevel(value) {
      this.level = value;
    },
  },
};
</script>
