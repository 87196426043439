var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-1/2 flex flex-col justify-center p-2",staticStyle:{"top":"0","left":"0","bottom":"0","right":"0","margin":"5% auto"}},[_vm._m(0),_c('div',{staticClass:"flex flex-col w-full my-6"},[_c('div',{staticClass:"border rounded-md w-full flex-col p-5"},[_c('div',[_c('radio-button',{attrs:{"space-between":"ml-2 mr-3 my-3 text-darkPurple text-xs flex flex-col","col-span":"col-span-12 hover-radioBtn","hover":"","options":[
            {
              name: 'Use my data, including current email & password as the account owner.',
              radioName: 'mode',
              value: true,
            },
          ]},model:{value:(_vm.selectMode),callback:function ($$v) {_vm.selectMode=$$v},expression:"selectMode"}})],1)])]),_c('ValidationObserver',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"border w-full flex flex-col p-6 bg-ghostWhite rounded-sm"},[_c('div',{staticClass:"w-full flex flex-col mb-2"},[_c('span',{staticClass:"text-base font-semibold my-2"},[_vm._v("Name of Organisation")]),_c('c-text',{staticClass:"label-input",attrs:{"placeholder":"e.g Scelloo","variant":"w-full h-10","label":"Name of Organisation","rules":['required']},on:{"input":function($event){return _vm.domainCheck($event)}},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}})],1),_c('div',{staticClass:"w-full flex flex-col justify-center my-2"},[_c('div',{staticClass:"flex my-2"},[_c('span',{staticClass:"text-base font-semibold flex flex-grow"},[_vm._v("Domain Name")]),_c('span',{staticClass:"flex text-sm text-flame"},[_vm._v("Domain "),_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`<p>
                This is required for Cloudenly to assign a unique workspace to your account.</p>`),expression:"`<p>\n                This is required for Cloudenly to assign a unique workspace to your account.</p>`"}],staticClass:"-mt-1 ml-2",attrs:{"icon-name":"icon-info-color","size":"xsm"}})],1)]),_c('div',{staticClass:"flex"},[_c('c-text',{staticClass:"label-input flex flex-grow",attrs:{"placeholder":"https://cloudenly.com/","variant":"w-full h-10","value":"https://cloudenly.com/","disabled":""}}),_c('c-text',{staticClass:"label-input flex flex-grow ml-2",attrs:{"placeholder":"e.g Scelloo","variant":"w-full h-10","errors":_vm.domainError,"label":"Domain","rules":['required']},on:{"input":function($event){return _vm.domainCheck($event)}},model:{value:(_vm.payload.alias),callback:function ($$v) {_vm.$set(_vm.payload, "alias", $$v)},expression:"payload.alias"}})],1)]),_c('div',{staticClass:"w-full flex flex-col my-2"},[_c('span',{staticClass:"text-base font-semibold my-2"},[_vm._v("Country")]),_c('c-select',{staticClass:"label-input",attrs:{"placeholder":"Select Country","variant":"w-full h-10 bg-white","label":"Country","options":_vm.countryOptions,"rules":['required']},model:{value:(_vm.payload.country),callback:function ($$v) {_vm.$set(_vm.payload, "country", $$v)},expression:"payload.country"}})],1)])]),_c('div',{staticClass:"pl-3 flex w-full justify-end mt-5"},[_c('div',{staticClass:"flex"},[_c('Button',{class:_vm.disableBtn ? `bg-disabled` : `bg-dynamicBackBtn text-white`,attrs:{"disabled":_vm.disableBtn},on:{"click":function($event){return handleSubmit(_vm.createOrg)}}},[_c('span',{staticClass:"mr-6 ml-6"},[_vm._v("Submit")])]),_c('Button',{staticClass:"ml-4",staticStyle:{"color":"#321c3b"},attrs:{"background-color":"none"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)])]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-xl font-black my-3"},[_vm._v(" Organisation Info ")]),_c('div',{staticClass:"w-full flex flex-grow"},[_c('span',{staticClass:"flex flex-grow text-base text-romanSilver"},[_vm._v("Provide the organisation information for your new account")]),_c('span',{staticClass:"flex"},[_c('span',{staticClass:"flex flex-grow text-base text-flame font-black"},[_vm._v("3")]),_c('span',{staticClass:"flex text-base text-romanSilver mx-1"},[_vm._v("of")]),_c('span',{staticClass:"flex flex-grow text-base text-romanSilver font-black"},[_vm._v("3")])])])])
}]

export { render, staticRenderFns }