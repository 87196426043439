<template>
  <div
    class="w-1/2 flex flex-col justify-center p-2"
    style="top: 0; left: 0; bottom: 0; right: 0; margin: 5% auto"
  >
    <div class="flex flex-col">
      <span class="text-xl font-black my-3"> Confirm Your Data </span>
      <div class="w-full flex flex-grow">
        <span class="flex flex-grow text-base text-romanSilver"
          >Confirm your data to proceed.</span
        >
        <span class="flex">
          <span class="flex flex-grow text-base text-flame font-black"
            >2</span
          >
          <span class="flex text-base text-romanSilver mx-1">of</span>
          <span class="flex flex-grow text-base text-romanSilver font-black"
            >3</span
          >
        </span>
      </div>
    </div>

    <div class="flex flex-col w-full my-6">
      <div class="border rounded-md w-full flex-col p-5">
        <div>
          <radio-button
            space-between="ml-2 mr-3 my-3 text-darkPurple text-xs flex flex-col"
            col-span="col-span-12 hover-radioBtn"
            hover
            v-model="selectMode"
            :options="[
              {
                name: 'Use my data, including current email & password as the account owner.',
                radioName: 'mode',
                value: true,
              },
            ]"
          />
        </div>
      </div>
    </div>

    <div class="flex w-full">
      <div class="border w-full flex flex-col p-6 bg-ghostWhite rounded-sm">
        <div class="w-full flex flex-col mb-3">
            <span class="text-sm font-semibold">Full Name:</span>
            <span class="text-base">{{ $AuthUser.fname }} {{ $AuthUser.lname }}</span>
        </div>
        <div class="w-full flex flex-col my-3">
            <span class="text-sm font-semibold">Contact Email:</span>
            <span class="text-base">{{ $AuthUser.email }}</span>
        </div>
        <div class="w-full flex flex-col my-3">
            <span class="text-sm font-semibold">Contact Phone No:</span>
            <span class="text-base">{{ $AuthUser.phoneNo }}</span>
        </div>
      </div>
    </div>

    <div class="pl-3 flex w-full justify-end mt-5">
      <div class="flex">
        <Button
          :disabled="disableBtn"
          :class="disableBtn ? `bg-disabled` : `bg-dynamicBackBtn text-white`"
          @click="moveNext()"
        >
          <span class="mr-6 ml-6">Next </span>
        </Button>

        <Button
          background-color="none"
          class="ml-4"
          style="color: #321c3b"
          @click="cancel"
        >
          Cancel
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from "@/components/RadioButton";
import Button from "@/components/Button";

export default {
  name: "SecondStep",
  components: {
    RadioButton,
    Button,
  },
  data() {
    return {
      selectMode: true,
      disableBtn: false,
    };
  },

  watch: {
    selectMode(value) {
      if (value) {
        this.disableBtn = false;
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("nextLevel", 1);
    },
    moveNext() {
      this.$emit("nextLevel", 3);
    },
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}
</style>
