<template>
  <div
    class="w-1/2 flex flex-col justify-center p-2"
    style="top: 0; left: 0; bottom: 0; right: 0; margin: 10% auto"
  >
    <div class="flex flex-col">
      <span class="text-xl font-black my-3"> Add a New Account </span>
      <div class="w-full flex flex-grow">
        <span class="flex flex-grow text-base text-romanSilver"
          >Please provide your new account details.</span
        >
        <span class="flex">
          <span class="flex flex-grow text-base text-flame font-black">1</span>
          <span class="flex text-base text-romanSilver mx-1">of</span>
          <span class="flex flex-grow text-base text-romanSilver font-black"
            >3</span
          >
        </span>
      </div>
    </div>

    <div class="flex flex-col w-full my-6">
      <div class="border rounded-md w-full flex-col p-5">
        <div>
          <radio-button
            space-between="ml-2 mr-3 my-3 text-darkPurple text-xs flex flex-col"
            col-span="col-span-12"
            hover
            :cancel="cancelAction"
            v-model="selectMode"
            @resetCancel="resetCancelBtn"
            :options="
            environment === 'production' || environment === 'staging' ?
            [
              {
                name: 'Use my data, including current email & password as the account owner.',
                radioName: 'mode',
                value: true,
              }
            ]:
            [
              {
                name: 'Use my data, including current email & password as the account owner.',
                radioName: 'mode',
                value: true,
              },
              {
                name: 'Use a different email and password for the new account',
                radioName: 'mode',
                value: false,
              },
            ]"
          />
        </div>
      </div>
    </div>

    <div class="pl-3 flex w-full justify-end mt-5">
      <div class="flex">
        <Button
          :disabled="disableBtn"
          :class="disableBtn ? `bg-disabled` : `bg-dynamicBackBtn text-white`"
          @click="moveNext()"
        >
          <span class="mr-6 ml-6">Next </span>
        </Button>

        <Button
          background-color="none"
          class="ml-4"
          style="color: #321c3b"
          @click="cancel"
        >
          Cancel
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from "@/components/RadioButton";
import Button from "@/components/Button";

export default {
  name: "FirstStep",
  components: {
    RadioButton,
    Button,
  },
  data() {
    return {
      selectMode: null,
      disableBtn: true,
      environment: process.env.NODE_ENV,
      cancelAction: false
    };
  },

  watch: {
    selectMode(value) {
      if (value) {
        this.disableBtn = false;
      }
    },
  },
  methods: {
    resetCancelBtn() {
      this.cancelAction = false
      this.selectMode = null;
      this.disableBtn = true;
    },

    cancel() {
      this.cancelAction = true;
    },
    moveNext() {
      if (this.selectMode === "true") {
        this.$emit("nextLevel", 2);
      } else if (this.selectMode === "false") {
        this.$handleLogout()
      }
    },
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}
</style>
