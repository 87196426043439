<template>
  <div
    class="w-1/2 flex flex-col justify-center p-2"
    style="top: 0; left: 0; bottom: 0; right: 0; margin: 5% auto"
  >
    <div class="flex flex-col">
      <span class="text-xl font-black my-3"> Organisation Info </span>
      <div class="w-full flex flex-grow">
        <span class="flex flex-grow text-base text-romanSilver"
          >Provide the organisation information for your new account</span
        >
        <span class="flex">
          <span class="flex flex-grow text-base text-flame font-black">3</span>
          <span class="flex text-base text-romanSilver mx-1">of</span>
          <span class="flex flex-grow text-base text-romanSilver font-black"
            >3</span
          >
        </span>
      </div>
    </div>

    <div class="flex flex-col w-full my-6">
      <div class="border rounded-md w-full flex-col p-5">
        <div>
          <radio-button
            space-between="ml-2 mr-3 my-3 text-darkPurple text-xs flex flex-col"
            col-span="col-span-12 hover-radioBtn"
            hover
            v-model="selectMode"
            :options="[
              {
                name: 'Use my data, including current email & password as the account owner.',
                radioName: 'mode',
                value: true,
              },
            ]"
          />
        </div>
      </div>
    </div>

    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <div class="flex w-full">
        <div class="border w-full flex flex-col p-6 bg-ghostWhite rounded-sm">
          <div class="w-full flex flex-col mb-2">
            <span class="text-base font-semibold my-2"
              >Name of Organisation</span
            >
            <c-text
              placeholder="e.g Scelloo"
              variant="w-full h-10"
              class="label-input"
              v-model="payload.name"
              @input="domainCheck($event)"
              label="Name of Organisation"
              :rules="['required']"
            />
          </div>
          <div class="w-full flex flex-col justify-center my-2">
            <div class="flex my-2">
              <span class="text-base font-semibold flex flex-grow"
                >Domain Name</span
              >
              <span class="flex text-sm text-flame"
                >Domain
                <Icon
                  icon-name="icon-info-color"
                  class="-mt-1 ml-2"
                  size="xsm"
                  v-tooltip="`<p>
                  This is required for Cloudenly to assign a unique workspace to your account.</p>`"
                />
              </span>
            </div>
            <div class="flex">
              <c-text
                placeholder="https://cloudenly.com/"
                variant="w-full h-10"
                class="label-input flex flex-grow"
                value="https://cloudenly.com/"
                disabled
              />
              <c-text
                placeholder="e.g Scelloo"
                v-model="payload.alias"
                variant="w-full h-10"
                :errors="domainError"
                class="label-input flex flex-grow ml-2"
                label="Domain"
                @input="domainCheck($event)"
                :rules="['required']"
              />
            </div>
          </div>
          <div class="w-full flex flex-col my-2">
            <span class="text-base font-semibold my-2">Country</span>
            <c-select
              placeholder="Select Country"
              variant="w-full h-10 bg-white"
              class="label-input"
              label="Country"
              :options="countryOptions"
              v-model="payload.country"
              :rules="['required']"
            />
          </div>
        </div>
      </div>

      <div class="pl-3 flex w-full justify-end mt-5">
        <div class="flex">
          <Button
            :disabled="disableBtn"
            :class="disableBtn ? `bg-disabled` : `bg-dynamicBackBtn text-white`"
            @click="handleSubmit(createOrg)"
          >
            <span class="mr-6 ml-6">Submit</span>
          </Button>

          <Button
            background-color="none"
            class="ml-4"
            style="color: #321c3b"
            @click="cancel"
          >
            Cancel
          </Button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import RadioButton from "@/components/RadioButton";
import Button from "@/components/Button";
import countries from "@/helpers/countries";

export default {
  name: "ThirdStep",
  components: {
    ValidationObserver,
    RadioButton,
    Button,
    CSelect,
    CText,
  },
  data() {
    return {
      selectMode: true,
      disableBtn: false,
      countryOptions: countries,
      payload: {
        name: "",
        alias: "",
        country: "",
      },
      domainError: [],
      domainExist: false,
    };
  },

  watch: {
    selectMode(value) {
      if (value) {
        this.disableBtn = false;
      }
    },
  },
  methods: {
    domainCheck(value) {
      const lowerCaseDomain = value.toLowerCase();
      const formatDomain = lowerCaseDomain.replaceAll(" ", "-");
      this.payload.alias = formatDomain;

      this.$_checkDomain(formatDomain).then((response) => {
        this.domainExist = response.data.aliasExists;
        if (this.domainExist) {
          this.domainError.push(`Domain name already in use`);
        } else {
          this.domainError = [];
        }
      });
    },

    cancel() {
      this.$emit("nextLevel", 2);
    },

    createOrg() {
      if (this.domainError.length === 0) {
        this.payload.userId = this.$AuthUser.id;
        this.$_createMultiOrganisation(this.payload)
          .then((response) => {
            const jwtToken = response.data.token;
            const linchpin = response.data;

            window.localStorage.setItem("linchpin", JSON.stringify(linchpin));

            window.localStorage.setItem("token", jwtToken);

            this.$toasted.success("New Account Added Successfully", {
              duration: 5000,
            });
            const newAccount = { new: true };
            window.localStorage.setItem(
              "setNewAccount",
              JSON.stringify(newAccount)
            );

            window.location.reload();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
    },
  },
};
</script>

<style>
.label-input label {
  display: none;
}
.label-input select {
  background-color: #ffffff;
}
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}
</style>
